<template>
  <section class="Add-Content">
    <div class="page-header">
      <h3 class="page-title">Edit Order</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/order/orders" class="text-primary"
              >Order
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Order</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="addeditOrder">
              <div class="mb-3">
                <label class="border-order-label">Select Customer</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-md-6">
                      <div class="form-group mb-2">
                        <label>Select Customer</label>
                        <multiselect
                          v-model="editOrder.user_id"
                          :options="useres"
                          placeholder="Select Customer"
                          label="first_name"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-2">
                        <label>Select Status</label>
                        <select
                          class="custom-select form-control"
                          v-model="editOrder.status_id"
                        >
                          <option value="" selected>Select Status</option>
                          <option
                            v-for="orderStatus in orderStatus"
                            :key="orderStatus.id"
                            v-bind:value="orderStatus.id"
                          >
                            {{ orderStatus.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Contact Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          v-model="editOrder.email"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          v-model="editOrder.mobile_number"
                          id="firstName"
                          name="firstName"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Landline Number</label>
                        <input
                          type="text"
                          v-model="editOrder.landline_number"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Comments</label>
                        <input
                          type="text"
                          v-model="editOrder.comments"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Billing Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Payment Mode</label>
                        <multiselect
                          v-model="editOrder.payment_mode_id"
                          label="name"
                          :options="paymentMode"
                          placeholder="Select Payment Mode"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Name</label>
                        <input
                          type="text"
                          v-model="editOrder.billingName"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Company</label>
                        <input
                          type="text"
                          v-model="editOrder.billingCompany"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Address Line 1</label>
                        <input
                          type="text"
                          v-model="editOrder.billingAddress_1"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Address Line 2</label>
                        <input
                          type="text"
                          v-model="editOrder.billingAddress_2"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>City</label>
                        <input
                          type="text"
                          v-model="editOrder.billingCity"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Country</label>
                        <multiselect
                          label="name"
                          v-model="editOrder.billingCountry"
                          :options="country"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Post Code</label>
                        <input
                          type="text"
                          v-model="editOrder.post_code"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check mt-0">
                        <label class="form-check-label text-muted">
                          <input
                            v-model="editOrder.save"
                            type="checkbox"
                            class="form-check-input form-group"
                          />
                          Save Billing Details
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="border-order-label">Shipping Details</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3">
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Shipping Mode</label>
                        <multiselect
                          v-model="editOrder.shipping_mode_id"
                          :options="shippingMode"
                          placeholder="Select Shipping Mode"
                          label="name"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.authorized_person"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Company</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.shippingCompany"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Address Line 1</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.shippingAddress_1"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Address Line 2</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.shippingAddress_2"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>City</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.shippingCity"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Country</label>
                        <multiselect
                          v-model="editOrder.shippingCountry"
                          :options="country"
                          placeholder="Select Country"
                          label="name"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3">
                        <label>Post Code</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="editOrder.shippingPost_code"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-check mt-0">
                        <label class="form-check-label text-muted">
                          <input
                            v-model="editOrder.shippingSave"
                            type="checkbox"
                            class="form-check-input form-group"
                          />
                          Save Shipping Details
                          <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label class="border-order-label">Products</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="pt-3">
                    <div
                      class="row"
                      v-for="(listOfProducts, index) in listOfProducts"
                      :key="listOfProducts.id"
                    >
                      <div class="col-6 col-md-4 pr-2">
                        <div class="form-group mb-3">
                          <label for="user_id">Product Name</label>
                          <!-- <multiselect
                           v-model="productList.productId" :options="products"
                            placeholder="Select Product Name" label="name"></multiselect> -->
                          <select
                            class="custom-select form-control"
                            v-model="listOfProducts.id"
                          >
                            <option value="" selected>Select Status</option>
                            <option
                              v-for="products in products"
                              :key="products.id"
                              v-bind:value="products.id"
                            >
                              {{ products.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6 col-md-4 pr-2 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Quantity</label>
                          <div v-if="listOfProducts.pivot">
                            <input
                              @change="productAmount"
                              type="number"
                              v-model="listOfProducts.pivot.product_quantity"
                              class="form-control"
                            />
                          </div>
                          <div v-else>
                            <input
                              @change="productAmount"
                              type="number"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-4 col-md-4 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Price </label>
                          <input
                            type="number"
                            disabled
                            v-model="listOfProducts.price"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!-- <div class="col-4 col-md-2 pr-2 pl-0">
                        <div class="form-group mb-3">
                          <label for="user_id">Amount </label>
                          <input type="number" disabled class="form-control" />
                        </div>
                      </div> -->
                      <!-- <div class="col-4 col-md-2 pr-0 pl-2">
                        <div class="pt-3" style="margin-top: 4px">
                          <button class="btn btn btn-add btn-primary" @click.prevent="addProduct()">
                            <i class="mdi mdi-plus"></i>
                          </button>
                          <button class="btn btn btn-add btn-dark" @click.prevent="removeProduct(index)">
                            <i class="mdi mdi-minus"></i>
                          </button>
                        </div>
                      </div> -->
                    </div>
                    <div class="row pt-3">
                      <div class="col-5 col-md-4 pr-1">
                        <div class="form-group mb-3">
                          <label for="user_id">Discount (add percentage)</label>
                          <input
                            type="text"
                            v-model="editOrder.discount_percentage"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-5 col-md-4 pl-1 pr-2">
                        <div class="form-group mb-3">
                          <label for="user_id">Shipping Charges </label>
                          <input
                            type="text"
                            disabled
                            v-model="editOrder.shippingCharges"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="col-2 col-md-1 pl-0 pr-0">
                        <div class="pt-3" style="margin-top: 4px">
                          <button
                            class="btn btn btn-add btn-dark"
                            @click.prevent="shippingCharges()"
                          >
                            <i class="mdi mdi-refresh"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row pt-3">
                      <div class="col-md-10 pr-2 col-9">
                        <div class="form-group mb-0" style="text-align: end;">
                          <label class="mb-2 w-100">Base Price :</label> 
                          <label class="mb-2  w-100">Shipping Charges :</label>
                          <p class="mb-0  w-100">Sub Total :</p>  
                        </div>
                      </div>
                        <div class="col-md-2 pl-0 col-3">
                        <div class="form-group mb-0">
                          <label class="mb-2  w-100"></label>
                          <label class="mb-2  w-100"></label>
                          <p class="mb-0  w-100"></p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 ">
                        <label class="w-100 border-bottom"></label>
                      </div>
                      <div class="col-md-10 pr-2 col-9">
                        <div class="form-group mb-3" style="text-align: end;">
                           <label class="mb-2 w-100">Discount Percentage :</label>
                           <label class="mb-2 w-100">Discount Price :</label>
                          <p class="mb-0  w-100">Total Amount :</p>
                          
                        </div>
                      </div>
                        <div class="col-md-2 pl-0 col-3">
                        <div class="form-group mb-3" >
                           <label class="mb-2  w-100">%</label>
                           <label class="mb-2  w-100"></label>
                              <p class="mb-0  w-100">{{editOrder.amount}}</p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label class="border-order-label">Submit</label>
                <div class="border p-3" style="margin-top: -9px">
                  <div class="row pt-3 pb-2">
                    <div class="col-12">
                      <button class="btn btn-primary btn-md btn-block">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { catalogApi, orderApi, userApi, authApi, setingsApi } from "../../api";

import Multiselect from "vue-multiselect";
export default {
  name: "new-order",
  components: {
    Multiselect,
  },
  data() {
    return {
      sss: [],
      // productList: "",

      orderStatus: [],
      shippingCountry: [],
      paymentMode: [],
      shippingMode: [],
      useres: [],
      country: [],
      products: [],
      listOfProducts: [
        {
          id: 1,
          productId: "",
          productQty: "",
        },
      ],
      editOrder: "",
      submitted: false,
    };
  },
  mounted() {
    this.listById();
    this.status();
    this.paymentModes();
    this.shippingModes();
    this.userList();
    this.countriesList();
    this.productsList();
  },
  methods: {
    async listById() {
      const { data } = await orderApi.listById(this.$route.params.id);
      this.editOrder = data.data.data;

      this.editOrder.forEach((val) => {
        this.editOrder.user_id = val.users;
        this.editOrder.payment_mode_id = val.payment_mode;
        this.editOrder.status_id = val.status_id;
        this.editOrder.mobile_number = val.mobile_number;
        this.editOrder.landline_number = val.landline_number;
        this.editOrder.email = val.email;
        this.editOrder.comments = val.comments;
        this.editOrder.billingName = val.billing_details.name;
        this.editOrder.billingCompany = val.billing_details.company;
        this.editOrder.billingAddress_1 = val.billing_details.address_1;
        this.editOrder.billingAddress_2 = val.billing_details.address_2;
        this.editOrder.billingCity = val.billing_details.city;
        this.editOrder.post_code = val.billing_details.post_code;
        this.editOrder.save = val.billing_details.save;
        this.editOrder.billingCountry = val.billing_details.country_id;
        this.editOrder.billingId = val.billing_details.id;
        this.editOrder.shipping_mode_id = val.shipping_services;
        this.editOrder.amount = val.amount;
        this.listOfProducts = val.products;
        // this.editOrder.productQty = val.products[0].pivot.product_quantity;
        // this.editOrder.shippingCharges = val.products[0].shipping
        // this.editOrder.price = val.products[0].price
        // this.editOrder.amount = val.products[0].price * val.products[0].pivot.product_quantity
      });
    },
    async status() {
      const { data } = await orderApi.status();
      this.orderStatus = data.data.data;
    },
    addProduct() {
      // this.listOfProducts.push({
      //   // id: this.listOfProducts.name,
      //   // p: this.listOfProducts.pivot.product_quantity,
      // });
    },
    async shippingCharges() {
      if (this.editOrder.shippingCountry) {

        this.editOrder.products = [];
        this.listOfProducts.forEach((value) => {
          var products = value.id;
          var productsQty = value.pivot.product_quantity;
          this.editOrder.products.push({
            id: products,
            qty: productsQty,
          });
        });
        let shippingCost = {
          country_id: this.editOrder.shippingCountry.id,
          products: this.editOrder.products,
        };

        const data = await orderApi.shippingCostCalculator({
          ...shippingCost,
        });
        // if (data == "") {
        //   this.editOrder.shippingCharges = 0;
        // } else {
        //   data.forEach((value) => {
        //     this.editOrder.shippingCharges = value.rate;
        //   });
        // }
      } else alert("Please add shipping address");
    },
    // discountAmount() {
    //   alert("please select any product");

    // },
    async productPrice() {
      const { data } = await catalogApi.getProductById(
        this.editOrder.productId.id
      );
      let products = data.data.data;
      products.forEach((value) => {
        if (this.editOrder.productQty == "") {
          this.editOrder.price = value.price;
          this.editOrder.amount = value.price * 0;
        } else {
          this.editOrder.price = value.price;
          this.editOrder.amount = value.price * this.editOrder.productQty;
        }
      });
    },
    async productAmount() {
      let amount = this.editOrder.price * this.editOrder.productQty;

      this.editOrder.amount = amount;
    },
    removeProduct(index) {
      this.listOfProducts.splice(index, 1);
    },

    async paymentModes() {
      const { data } = await setingsApi.paymentModes();
      this.paymentMode = data.data.data;
    },
    async shippingModes() {
      const { data } = await setingsApi.shipping();
      this.shippingMode = data.data.data;
    },
    async userList() {
      const { data } = await userApi.userList();
      this.useres = data.data.data;
    },
    async countriesList() {
      const { data } = await authApi.countries();
      this.country = data;
    },
    async productsList() {
      const { data } = await catalogApi.productsList();
      this.products = data.data.data;
    },
    async addeditOrder() {
      this.submitted = true;

      // this.editOrder.shipping = [];
      this.editOrder.billing = [];

      this.editOrder.product_id = [];
      this.editOrder.product_quantity = [];

      if (this.editOrder.user_id) {
        this.editOrder.user_id = this.editOrder.user_id.id;
      }

      if (this.editOrder.status_id) {
        this.editOrder.status_id = this.editOrder.status_id.id;
      }

      if (this.listOfProducts) {
        this.listOfProducts.forEach((value) => {
          var products = value.id;
          var productsQty = value.pivot.product_quantity;

          this.editOrder.product_id.push(products);
          this.editOrder.product_quantity.push(productsQty);
        });
      }

      // if (this.editOrder.productQty) {
      //   var productsQty = this.editOrder.productQty;
      //   this.editOrder.product_quantity.push(productsQty);
      // }

      if (this.editOrder.payment_mode_id) {
        this.editOrder.payment_mode_id = this.editOrder.payment_mode_id.id;
      }

      if (this.editOrder.save === true) {
        this.editOrder.save = 1;
      } else {
        this.editOrder.save = 0;
      }

      this.editOrder.billing = {
        name: this.editOrder.billingName,
        company: this.editOrder.billingCompany,
        address_1: this.editOrder.billingAddress_1,
        address_2: this.editOrder.billingAddress_2,
        city: this.editOrder.billingCity,
        id: this.editOrder.billingId,
        // country_id: this.editOrder.billingCountry.id,
        post_code: this.editOrder.post_code,
        save: this.editOrder.save,
      };

      if (this.editOrder.shipping_mode_id) {
        this.editOrder.shipping_mode_id = this.editOrder.shipping_mode_id.id;
      }
      if (this.editOrder.shippingCountry) {
        this.editOrder.shippingCountry = this.editOrder.shippingCountry.id;
      }

      if (this.editOrder.shippingSave === true) {
        this.editOrder.shippingSave = 1;
      } else {
        this.editOrder.shippingSave = 0;
      }

      // this.editOrder.shipping = {
      //   authorized_person: this.editOrder.authorized_person,
      //   company: this.editOrder.shippingCompany,
      //   address_1: this.editOrder.shippingAddress_1,
      //   address_2: this.editOrder.shippingAddress_2,
      //   city: this.editOrder.shippingCity,
      //   id: 9,
      //   // country_id: this.editOrder.shippingCountry,
      //   post_code: this.editOrder.shippingPost_code,
      //   save: this.editOrder.shippingSave,
      // };

      for (var key in this.editOrder) {
        if (this.editOrder.hasOwnProperty(key)) {
          var val = this.editOrder[key];
          if (val === null) {
            delete this.editOrder[key];
          }
        }
        delete this.editOrder["billingName"];
        delete this.editOrder["billingCompany"];
        delete this.editOrder["billingAddress_1"];
        delete this.editOrder["billingAddress_2"];
        delete this.editOrder["billingCity"];
        delete this.editOrder["billingCountry"];
        delete this.editOrder["post_code"];
        delete this.editOrder["billingId"];
        delete this.editOrder["save"];

        delete this.editOrder["authorized_person"];
        delete this.editOrder["shippingCompany"];
        delete this.editOrder["shippingAddress_1"];
        delete this.editOrder["shippingAddress_2"];
        delete this.editOrder["shippingCity"];
        delete this.editOrder["shippingCountry"];
        delete this.editOrder["shippingPost_code"];
        delete this.editOrder["shippingSave"];

        delete this.editOrder["productId"];
        delete this.editOrder["productQty"];
      }

      const data = await orderApi.updateOrder(this.$route.params.id, {
        ...this.editOrder,
      });

      this.$router.push("/order/orders");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }

      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.editOrder));
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.border-order-label {
  background: #fff;
  margin-left: 22px;
  padding: 0px 9px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 15px;
}

.form-group label {
  font-size: 12px;
}

.border {
  border: 1px solid #2125299e !important;
}
</style>
